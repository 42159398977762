import { __subscription } from "~/stores/subscription.store";

import { PBEnums } from "~/types/pb/enums";

import { log } from "~/composables/log";

export default defineNuxtRouteMiddleware(() => {
    if (__subscription().getQuota(PBEnums.Abonnements.S3) === 0) {
        log("❌ Has no S3 quota");
        return navigateTo("/");
    }

    log("✅ Has S3 quota");
});
